(function($){
    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        dots: false,
        nav: true,
        autoplay:true,
        autoplayTimeout:1000,
        autoplayHoverPause:true,
        responsiveClass:true,
        navText: [
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>"
        ],
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items: 6
            }
        }
    });
})(jQuery);